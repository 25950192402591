<template>
  <div class="ps-section__right">
    <div class="ps-section--account-setting">
      <div class="ps-section__content">
        <div class="d-sm-flex align-items-center justify-content-between mb-4 mt-4">
          <h3>Opening Hours</h3>
          <div class="justify-content-between">
            <router-link :to="{name: 'merchant_create_hours'}" class="ps-btn btn-sm">Add New</router-link>
          </div>
        </div>

        <div class="table-responsive">
          <table class="table ps-table ps-table--vendor">
            <thead>
            <tr>
              <th>Day of Week</th>
              <th>Opening</th>
              <th>Closing</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="shopHour in shopHours" :key="shopHour.id">
                <td>{{ shopHour.day_of_week }}</td>
                <td>{{ shopHour.start_hour }}</td>
                <td>{{ shopHour.end_hour }}</td>
                <td>
                  <router-link :to="{name: 'merchant_edit_hours', params: {id: shopHour.id}}"><i class="fa fa-edit" /></router-link>
                  <a class="ml-3" href="#" v-on:click="onDelete(shopHour.id)"><i class="fa fa-trash" /></a>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr v-if="(!shopHours || shopHours.length === 0) && !isLoading">
                <td colspan="6" class="text-center m-4 text-danger">No shop hours available</td>
              </tr>
              <tr v-else-if="isLoading">
                <td colspan="6" class="text-center m-4 text-danger">Loading shop hours...</td>
              </tr>
            </tfoot>
          </table>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {mapState} from "vuex";

export default {
  data() {
    return {
      isLoading: true,
      shopHours: [],
      meta: null
    };
  },
  mounted() {
    this.getShopHours();
  },
  computed: {
    ...mapState({
      auth: state => state.auth
    }),
  },
  methods: {
    getShopHours() {
      this.isLoading = true
      axios.get("shop-hours").then((response) => {
        this.shopHours = response.data;
        this.isLoading = false;
      }).catch((error) => {
        this.isLoading = false;
      });
    },

    onDelete(shopHourId) {
      if (confirm('Are you sure you want to delete this shop hour')) {
         axios.delete('shop-hours/' + shopHourId).then((response) => {
            this.getShopHours();
         });
      }
    }
  }
}
</script>

<style>
</style>